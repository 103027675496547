import React, { useCallback, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import ReactFlow, { getIncomers, getOutgoers, Edge, Node, MarkerType, useNodesState, useEdgesState, addEdge, getConnectedEdges, Background, Controls, BackgroundVariant, OnSelectionChangeParams, useReactFlow, getRectOfNodes, getTransformForBounds } from 'reactflow';
import 'reactflow/dist/style.css';
import { NodeType, SaveTarget, Toolbar } from '../Toolbar/Toolbar';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { downloadImage } from '../../utils/image-download';

const BASE_EDGE_PROPS: Partial<Edge> = {
  markerEnd: {
    type: MarkerType.Arrow,
    width: 30,
    height: 30,
    color: '#000'
  },
  data: {
    description: ''
  },
  label: ''
};

const NODE_TYPE_MAP: Record<NodeType, { color: string, name: string }> = {
  [NodeType.MAIN_METER]: {
    name: 'Főmérő',
    color: '#d8d809'
  },
  [NodeType.BUILDING_METER]: {
    name: 'Épületszintű mérő',
    color: '#ad2c04'
  },
  [NodeType.SUB_METER]: {
    name: 'Almérő',
    color: '#f64583'
  },
  [NodeType.SHARED_METER]: {
    name: 'Közös',
    color: '#0971d8'
  },
  [NodeType.DIFFERENTIAL]: {
    name: 'Különbözet',
    color: '#db7500'
  },
  [NodeType.RENTER1]: {
    name: 'Bérlő 1',
    color: '#16c263'
  },
  [NodeType.RENTER2]: {
    name: 'Bérlő 2',
    color: '#5b8677'
  },
  [NodeType.RENTER3]: {
    name: 'Bérlő 3',
    color: '#033a23'
  },
};

const initialNodes: Node[] = [
  // { id: '1', position: { x: 0, y: 0 }, data: { label: '1' } },
  // { id: '2', position: { x: 0, y: 100 }, data: { label: '2' } },
];

const initialEdges: Edge[] = [
  // { id: 'e1-2', source: '1', target: '2', label: 'Hely', ...BASE_EDGE_PROPS }
];

export type FlowHandleRef = {
  loadContent: (contents: any) => void
};

export const Flow = forwardRef((props, ref) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [currentNode, setCurrentNode] = useState<Node | null>(null);
  const [currentEdge, setCurrentEdge] = useState<Edge | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const labelEditTextRef = useRef<HTMLInputElement | null>(null);
  const descriptionEditTextRef = useRef<HTMLTextAreaElement | null>(null);
  const { getEdges, getNodes } = useReactFlow();

  useImperativeHandle(ref, () => ({
    loadContent: loadContent
  }));

  const onConnect = useCallback((params: any) => {
    setEdges((eds) => addEdge({ ...params, ...BASE_EDGE_PROPS }, eds))
  }, [setEdges]);

  const newNode = (nodeType: NodeType) => {
    const nodeTypeData = NODE_TYPE_MAP[nodeType];

    const newNode: Node = {
      id: `${nodeType}-${Date.now()}`,
      position: {
        x: 100,
        y: 100
      },
      data: {
        type: nodeType,
        label: nodeTypeData.name,
      },
      style: {
        background: nodeTypeData.color
      }
    };

    console.log(newNode);
    setNodes((nds) => nds.concat(newNode));
  }

  const changeEdgeLabel = (event: any, edge: Edge) => {
    descriptionEditTextRef.current!.value = edge.data?.description || '';
    labelEditTextRef.current!.value = edge.label?.toString() || '';

    setCurrentEdge(edge);
    showModal();
  }

  const changeNodeLabel = (event: any, node: Node) => {
    descriptionEditTextRef.current!.value = node.data?.description || '';
    labelEditTextRef.current!.value = node.data?.label || '';

    setCurrentNode(node);
    showModal();
  }

  const loadFromFile = async () => {
    try {
      const [fileHandle] = await window.showOpenFilePicker({
        types: [
          {
            description: 'JSON files',
            accept: {
              'application/json': ['.json']
            }
          }
        ],
        excludeAcceptAllOption: true,
        multiple: false
      });

      if (!fileHandle) {
        return;
      }

      const file = await fileHandle.getFile();
      const contents = JSON.parse(await file.text());

      loadContent(contents);
    } catch {
      alert('Fájl olvasási hiba!');
    }

  }

  const loadContent = (contents: any) => {
    if (!Array.isArray(contents.edges) || !Array.isArray(contents.nodes)) {
      alert('Helytelen fájltartalom!');
      return;
    }

    /**
     * átmeneti megoldás: a régi "renter" típusú csomópontokat átkonvertálom 'renter-1'-re
     */
    contents.nodes = contents.nodes.map((node: any) => {
      if (node.data.type === "renter") {
        node.data.type = NodeType.RENTER1;
        node.style.background = NODE_TYPE_MAP[NodeType.RENTER1].color;
      }

      return node;
    });

    setEdges(contents.edges);
    setNodes(contents.nodes);
  }

  const saveToFile = async () => {
    try {
      stopHighlight();

      const fileHandle = await window.showSaveFilePicker({
        types: [
          {
            description: 'JSON files',
            accept: {
              'application/json': ['.json']
            }
          }
        ],
        excludeAcceptAllOption: true
      });

      if (!fileHandle) {
        return;
      }

      const writable = await fileHandle.createWritable();

      await writable.write(JSON.stringify({
        edges: edges,
        nodes: nodes
      }));

      writable.close();
      alert('Fájl sikeresen elmentve!');
    } catch {
      alert('Fájl olvasási hiba!');
    }

  }

  const saveToParent = () => {
    console.log('Sending postMessage to parent window...', { type: 'save', parentWindow: window.parent });
    window.parent?.postMessage({
      type: 'save',
      contents: {
        edges,
        nodes
      }
    }, '*');
  }

  const saveImage = () => {    
    const nodeBounds = getRectOfNodes(getNodes());

    const width = nodeBounds.width * 2;
    const height = nodeBounds.height * 2;
    
    const transform = getTransformForBounds(nodeBounds, width, height, 0.5, 2);

    downloadImage(transform, width, height);
  }

  const closeModal = (abort: boolean) => {
    setIsModalOpen(false);

    if (abort) {
      setCurrentEdge(null);
      setCurrentNode(null);
      return;
    }


    if (currentEdge) {
      const newLabel = labelEditTextRef.current?.value || '';
      const newDescription = descriptionEditTextRef.current?.value || '';

      setEdges((edgs) => {
        const targetEdge = edgs.find((e) => e.id === currentEdge.id);
        if (targetEdge) {
          targetEdge.label = newLabel;
          targetEdge.data.description = newDescription;
        }

        return [...edgs];
      });
      setCurrentEdge(null);
    }

    if (currentNode) {
      const newLabel = labelEditTextRef.current?.value || '';
      const newDescription = descriptionEditTextRef.current?.value || '';

      setNodes((nds) => {
        const targetNode = nds.find((n) => n.id === currentNode.id);
        if (targetNode) {
          targetNode.data = {
            ...targetNode.data,
            description: newDescription,
            label: newLabel
          };
        }

        return [...nds];
      });
      setCurrentNode(null);
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  }

  const highlightPath = (highlightedNodes: Node[]) => {
    console.log(`Highlighting ${highlightedNodes.length} node(s)`, highlightedNodes);

    let allNodesOut: Node[] = [];
    let allNodesIn: Node[] = [];
    let allConnectedEdges: Edge[] = [];

    highlightedNodes.forEach(node => {
      allNodesOut = [...allNodesOut, ...getOutgoers(node, getNodes(), getEdges()), node/* put the node itself in, so it gets highlighted as well */];
      allNodesIn = [...allNodesIn, ...getIncomers(node, getNodes(), getEdges())];
      allConnectedEdges = [...allConnectedEdges, ...getConnectedEdges([node], getEdges())];
    });

    const nodeSet = new Set<string>([...allNodesIn, ...allNodesOut].map(n => n.id));
    const edgeSet = new Set<string>(allConnectedEdges.map(e => e.id));

    setEdges(edgs => {
      return edgs.map(edge => {
        if (edgeSet.has(edge.id)) {
          edge.style = {
            ...edge.style,
            stroke: '#000',
            opacity: 1
          }
        } else {
          edge.style = {
            ...edge.style,
            stroke: '#555',
            opacity: 0.2
          }
        }

        return edge;
      });
    });

    setNodes(nds => {
      return nds.map(node => {
        if (nodeSet.has(node.id)) {
          node.style = {
            ...node.style,
            opacity: 1
          };
        } else {
          node.style = {
            ...node.style,
            opacity: 0.2
          };
        }

        return node;
      });
    });
  }

  const stopHighlight = () => {
    console.log('Clearing highlights');
    setEdges((edgs) => edgs.map(edge => {
      if (edge.style) {
        const { opacity, stroke, ...newStyle } = edge.style;
        edge.style = newStyle;
        return edge;
      }
      return edge;
    }));

    setNodes((nds) => nds.map(node => {
      if (node.style) {
        const { opacity, ...newStyle } = node.style;
        node.style = newStyle;
        return node;
      }
      return node;
    }));
  }

  const selectionChange = useCallback((params: OnSelectionChangeParams) => {
    stopHighlight();

    if (params.nodes.length === 0) {
      return;
    }

    highlightPath(params.nodes);
  }, []);


  return (
    <div className="Flow">
      <Rodal
        visible={isModalOpen}
        onClose={() => closeModal(true)}
        width={800}
        height={400}
        closeMaskOnClick={false}
        closeOnEsc={false}
        customStyles={{

        }}>
        <div className="modal-content">
          <h2>Cimke és leírása szerkesztése</h2>

          <input type={"text"} ref={labelEditTextRef} placeholder="Cimke..." />

          <textarea ref={descriptionEditTextRef} style={{
            resize: 'none',
            fontFamily: 'Arial',
            fontSize: '16px'
          }}
            placeholder="Leírás...">
          </textarea>

          <div className="modal-footer">
            <button onClick={() => closeModal(true)} className="btn secondary">Mégse</button>
            <button onClick={() => closeModal(false)} className="btn primary">Mentés</button>
          </div>
        </div>
      </Rodal>

      <Toolbar
        onNewNode={newNode}
        onLoad={loadFromFile}
        onSave={(target) => target === 'file' ? saveToFile() : saveToParent()}
        onSaveImage={saveImage}/>


      <div className="flow-wrapper">

        <ReactFlow
          style={{
            color: '#fff'
          }}
          edges={edges}
          nodes={nodes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeDoubleClick={changeEdgeLabel}
          onNodeDoubleClick={changeNodeLabel}
          onConnect={onConnect}
          fitView={true}
          deleteKeyCode={'Delete'}
          onSelectionChange={selectionChange}>
          <Controls />
          <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        </ReactFlow>

      </div>
    </div>
  );
});